<template>
  <div v-loading="loading" class="card-container">
    <div class="card">
      <detailsHeader :hidden-back="true" :show-popver="false" />
    </div>

    <div v-if="imgList.length" :class="'imgcard'">
      <div v-for="(item, index) in imgList" :key="index" class="img">
        <img :src="item" alt="" />
      </div>
    </div>
    <div v-else class="card">
      <no-data></no-data>
    </div>
  </div>
</template>
<script>
import detailsHeader from "@/components/detailsHeader.vue";
export default {
  name: "Card",
  components: {
    detailsHeader,
  },
  data() {
    return {
      imgList: [],
      loading: false,
    };
  },
  mounted() {
    this.getData();
  },
  methods: {
    async getData() {
      this.loading = true;
      const answerSheetUrl = sessionStorage.getItem("imgList");
      this.imgList = answerSheetUrl.split("，");
      this.loading = false;
    },
  },
};
</script>
<style lang="scss" scoped>
.card-container {
  width: 100%;
  .questions {
    width: 100%;
    .img {
      text-align: center;
      margin-bottom: 20px;
      height: auto;
    }
  }
  .imgcard {
    width: 100%;
    .img {
      text-align: center;
      margin-bottom: 20px;
      height: auto;
      img {
        width: 100%;
        height: auto;
      }
    }
  }
}
</style>
